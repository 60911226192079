import React, { Component } from "react";
import NavBar from "./NavBar";
import { NavLink } from "react-router-dom";
import logo from "../assets/house.png";
import "./Header.css";

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <header className="header-bg-image">
          <div className="d-flex pt-3 pb-3">
            <NavLink exact className="nav-item nav-link" to="/">
              <img className="img-fluid mr-1" src={logo} alt="Logo" />
            </NavLink>
            <div>
              <h2 className="text-light mb-0 font-weight-bold">Houseshakers</h2>
              <div className="brand-color">
                Central{" "}
                <span className="ohio-color font-weight-bold">Ohio</span> Band
              </div>
              <div className="text-light mt-2">
                Professional Entertainment - Tasteful Music
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
