import React, { Component } from "react";

class SubSection extends Component {
  render() {
    return (
      <div className="sub_section">
        <h4>{this.props.title}</h4>
        {this.props.children}
      </div>
    );
  }
}

export default SubSection;
