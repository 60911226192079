import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footer text-right black-background pr-2">
        <small className="text-muted">©2021 Houseshakers Band</small>
      </div>
    );
  }
}

export default Footer;
