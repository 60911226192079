import React, { Component } from "react";
import drums from "../assets/drums.svg";

class MainSection extends Component {
  render() {
    return (
      <div className="main_section pl-4 pr-4 clearfix">
        <h3>
          <img src={drums} alt="" className="mr-2" />
          <span
            style={{
              display: "inline-block",
              verticalAlign: "bottom",
              color: "#a197bf",
              fontWeight: "bold"
            }}
          >
            {this.props.title}
          </span>
        </h3>
        <div className="ml-3 mr-3">{this.props.children}</div>
      </div>
    );
  }
}

export default MainSection;
