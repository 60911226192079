import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

class NavBar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-md float-right px-1 py-1">
        {/* <button
          className="navbar-toggler navbar-dark"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
        <div className="navbar-nav">
          <NavLink exact className="nav-item nav-link" to="/">
            Home
          </NavLink>
          {/* <NavLink className="nav-item nav-link" to="/schedule">
            Schedule
          </NavLink> */}
          <NavLink className="nav-item nav-link" to="/contact">
            Contact Us
          </NavLink>
        </div>
        {/* </div> */}
      </nav>
    );
  }
}

export default NavBar;
