import React, { Component } from "react";
import "./ScheduleTable.css";

import call_houseshakers_api from "../helpers/api";

class ScheduleTable extends Component {
  state = {
    schedule: null,
    scheduleLoadStatus: "waiting"
  };

  componentDidMount() {
    call_houseshakers_api("GET", "schedule")
      .then(json => {
        this.setState({
          schedule: json.schedule,
          scheduleLoadStatus: "loaded"
        });
      })
      .catch(() => {
        this.setState({ scheduleLoadStatus: "error" });
      });
  }

  render() {
    let items = [];
    if (this.state.scheduleLoadStatus === "loaded") {
      let idx = 0;
      if (this.state.schedule) {
        for (let item of this.state.schedule) {
          items.push(
            <div
              key={"schedule_item_" + idx.toString()}
              className="schedule_table_item mt-4"
            >
              <small className="d-block align-top mr-2 mt-2">
                <span className="border-bottom border-primary pr-2">
                  {item.date} at {item.time}
                </span>
              </small>
              <div className="d-inline-block">
                <h3 className="">{item.venue} </h3>
                <div className="text-muted">{item.address}</div>
                <div className="mt-2">{item.comment}</div>
              </div>
            </div>
          );
          ++idx;
        }
      }
    } else if (this.state.scheduleLoadStatus === "waiting") {
      items = (
        <div className="spinner-border text-primary m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else {
      items = (
        <div className="text-muted m-5">
          Schedule not available at this time
        </div>
      );
    }
    return <div className="schedule_table">{items}</div>;
  }
}

export default ScheduleTable;
