import React, { Component } from "react";

class AudioPlayer extends Component {
  render() {
    return (
      <div className="audio_player">
        <figure>
          <figcaption>{this.props.title}</figcaption>
          <audio controls src={this.props.src}>
            Your browser does not support the <code>audio</code> element.
          </audio>
        </figure>
      </div>
    );
  }
}

export default AudioPlayer;
