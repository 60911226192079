import { APIServer } from "../Globals";

export function call_api(method, url, data = null) {
  const LogError = (operation, message) => {
    console.log(`${operation} fail\nURL: ${url}\nMessage: ${message}`);
  };

  let request = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  if (data) request.body = JSON.stringify(data);

  return new Promise((resolve, reject) => {
    let api_res = null;

    fetch(url, request)
      .then(res => {
        api_res = res;
        if (!res.ok) LogError("API", res.statusText);
        return res.json();
      })
      .then(json => {
        if (!api_res.ok) {
          // already logged by previous then()
          reject({ message: api_res.statusText, json: json });
        } else {
          resolve(json);
        }
      })
      .catch(message => {
        LogError("fetch()", message);
        reject({ message: api_res.statusText });
      });
  });
}

export default function call_houseshakers_api(method, endpoint, data = null) {
  return new Promise((resolve, reject) => {
    call_api(method, APIServer + "/" + endpoint, data)
      .then(json => {
        if (!json.ok) {
          console.log(
            `HS API fail\nEndpoint: ${endpoint}\nMessage: ${json.message}`
          );
          reject({ message: json.message, errors: json.errors });
        } else {
          resolve(json);
        }
      })
      .catch(reject);
  });
}
