import React, { Component } from "react";
import { ReCaptcha } from "react-recaptcha-v3";

class ReCaptchaControl extends Component {
  state = { verified: false };

  onVerify = recaptchaToken => {
    this.props.onVerify(recaptchaToken);

    this.setState({ verified: true });
  };

  render() {
    let status = this.state.verified ? null : (
      <div className="text-muted">Waiting on Google verification...</div>
    );
    return (
      <div className="recaptcha_control">
        <ReCaptcha
          sitekey="6LelJ9IUAAAAAHqjurcHMWW53FROLV2jc0YiSYA8"
          action="bandContactForm"
          verifyCallback={this.onVerify}
        />

        {status}
      </div>
    );
  }
}

export default ReCaptchaControl;
