import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-v3";
import Header from "./components/Header";
import Footer from "./components/Footer";
import OurMusic from "./sections/OurMusic";
import OurSchedule from "./sections/OurSchedule";
import ContactUs from "./sections/ContactUs";
import { ReCaptchaSiteKey } from './Globals';
import "./App.css";

class App extends Component {
  static addAutoPauseAudioListener() {
    document.addEventListener(
      "play",
      e => {
        const thisAudio = e.target;
        const audios = document.getElementsByTagName("audio");
        for (const audio of audios) {
          if (audio !== thisAudio) audio.pause();
        }
      },
      true
    );
  }

  componentDidMount() {
    loadReCaptcha(ReCaptchaSiteKey);
    App.addAutoPauseAudioListener();
  }

  render() {
    return (
      <div className="container">
        <Router>
          <Header />
          <Route exact path="/">
            <OurMusic />
          </Route>
          <Route path="/schedule">
            <OurSchedule />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
