import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainSection from "../components/MainSection";
import SubSection from "../components/SubSection";
// import VideoPlayer from "../components/VideoPlayer";
import AudioPlayer from "../components/AudioPlayer";
import "./OurMusic.css";

//import BlackVelvet from "../assets/audio/blackvelvet.mp4";
//import CrazyOnYou from "../assets/audio/crazyonyou.mp4";
//import ReelinInTheYears from "../assets/audio/reelinintheyears.mp4";
//import WhenWillIBeLoved from "../assets/audio/whenwillibeloved.mp3";
//import CallMeAl from "../assets/audio/callmeal.mp3";
//import JackAndDiane from "../assets/audio/jackanddiane.mp3";
//import ThinkingAboutYou from "../assets/audio/thinkingaboutyou.mp3";
import Soulshine from "../assets/audio/soulshine.mp3";
import CallingElvis from "../assets/audio/callingelvis.mp3";
import AintNothinYouCanDo from "../assets/audio/aintnothinyoucando.mp3";
import Pretender from "../assets/audio/pretender.mp3";
import ShameOnYou from "../assets/audio/shameonyou.mp3";

class OurMusic extends Component {
  render() {
    return (
      <MainSection title="Our Music">
        <div className="row">
          {/* <div className="col order-lg-last mb-3 mb-lg-0">
            <VideoPlayer title="Video Coming Soon!" />
          </div> */}
          <div className="col-sm-12 col-lg order-lg-first">
            <SubSection title="Quick Overview">
              {/* <p>
                If you only have a minute or you just want to hear what we do,
                check out our promo video.
              </p> */}

              <div className="row">
                <div className="col-12 col-lg border-right">
                  <p>
                    The Houseshakers are a multi-influence band from the heart
                    of Central Ohio. Formed around the time of the new
                    millennium and with a repertoire that spans over five
                    decades, The Houseshakers bring a diverse musical
                    background. With genres ranging from country to progressive,
                    from classic rock to contemporary rock, the Houseshakers
                    will fill any room with heartfelt music.
                  </p>
                </div>
                <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                  <ul className="band_members_list list-unstyled">
                    <li>
                      Michael Welsh <i>- Drums</i>
                    </li>
                    {/* <li>
                      Kelly Davis <i>- Vocals / Guitar / Sax / Piano</i>
                    </li> */}
                    <li>
                      Steven Smith <i>- Vocals / Bass</i>
                    </li>
                    <li>
                      Ian Walker <i>- Vocals / Guitar / Piano</i>
                    </li>
                  </ul>
                </div>
              </div>
            </SubSection>
            <SubSection title="Full Tracks">
              <div className="row">
                <div className="col-12 col-lg order-lg-last mt-lg-3">
                  <p>
                    Here are a few tracks we've managed to record over the years.
                    If you like what you hear 
                    on the website then you'll really enjoy our live performances!
                    {/* check out <Link to="/schedule">our schedule</Link> so you can
                    tell us in person! */}
                  </p>
                </div>
                <div className="col-12 col-lg-auto ord-lg-first mt-3 mt-lg-0 ml-4 ml-lg-0">
                  <div>
                    <AudioPlayer title="Shame On You" src={ShameOnYou} />
                    <AudioPlayer title="Pretender" src={Pretender} />
                    <AudioPlayer title="Ain't Nothin' You Can Do" src={AintNothinYouCanDo} />
                    <AudioPlayer title={<>Soulshine <em>(with Kelly Davis)</em></>} src={Soulshine} />
                    <AudioPlayer title="Calling Elvis" src={CallingElvis} />
                  </div>
                </div>
              </div>
            </SubSection>
          </div>
        </div>
      </MainSection>
    );
  }
}

export default OurMusic;
