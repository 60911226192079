import React, { Component } from "react";
import MainSection from "../components/MainSection";
import ContactForm from "../components/ContactForm";

class ContactUs extends Component {
  render() {
    return (
      <MainSection title="Contact Us">
        <div className="text-center">
          <p className="d-block text-left">
            We've made it easy to reach us. Just fill out this short form and
            we'll be in touch...
          </p>
          <ContactForm />
        </div>
      </MainSection>
    );
  }
}

export default ContactUs;
