import React, { Component } from "react";

class ContactFormField extends Component {
  handleKeyPress = e => {
    // block Enter key from all but text controls
    // so that user doesn't accidentally submit form
    if (e.key === "Enter") e.preventDefault();
  };

  render() {
    const type = this.props.type || "text";

    let control;
    switch (type) {
      case "textarea":
        control = (
          <textarea
            value={this.props.value}
            onChange={this.props.onChange}
            className="align-top"
            name={this.props.name}
            id={this.props.id}
            required={this.props.required}
          ></textarea>
        );
        break;
      case "radio":
        {
          control = [];

          let idx = 0;
          for (let opt of this.props.options) {
            let id = this.props.id + "_" + idx.toString();
            control.push(
              <div key={id}>
                <input
                  type="radio"
                  id={id}
                  name={this.props.name}
                  value={opt.value}
                  checked={opt.value === this.props.value}
                  onChange={this.props.onChange}
                  onKeyPress={this.handleKeyPress}
                  required={this.props.required}
                />
                <label className="ml-1 mr-3" htmlFor={id}>
                  {opt.label}
                </label>
              </div>
            );
            ++idx;
          }

          control = <div>{control}</div>;
        }
        break;
      default: {
        control = (
          <input
            value={this.props.value}
            onChange={this.props.onChange}
            onKeyPress={this.handleKeyPress}
            type={type}
            name={this.props.name}
            id={this.props.id}
            required={this.props.required}
          />
        );
      }
    }

    let subtext_control = "";
    if (this.props.subtext) {
      subtext_control = (
        <div className="mb-2">
          <small>
            <em>{this.props.subtext}</em>
          </small>
        </div>
      );
    }

    return (
      <div className="contact_form_field">
        <label className="contact_form_label mr-3" htmlFor={this.props.id}>
          {this.props.label}
        </label>
        <div className="d-inline-block align-top">
          {control}
          {subtext_control}
        </div>
      </div>
    );
  }
}

export default ContactFormField;
