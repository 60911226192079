import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainSection from "../components/MainSection";
import ScheduleTable from "../components/ScheduleTable";

class OurSchedule extends Component {
  render() {
    return (
      <MainSection title="Our Schedule">
        <p>
          Want to book us? <Link to="/contact">Contact Us</Link> and we'll get
          it done! You may also catch us playing impromptu gigs across Ohio ...
          because we can{" "}
          <span aria-labelledby="wink" role="img">
            &#128521;
          </span>
        </p>
        <ScheduleTable />
      </MainSection>
    );
  }
}

export default OurSchedule;
