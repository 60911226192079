import React, { Component } from "react";
import ContactFormField from "./ContactFormField";
import ReCaptchaControl from "./ReCaptchaControl";
import "./ContactForm.css";

import call_houseshakers_api from "../helpers/api";

class ContactForm extends Component {
  state = {
    reason: "general",
    method: "email",
    submitterName: "",
    email: "",
    phone: "",
    contactName: "",
    venue: "",
    address: "",
    date: "",
    time: "",
    comments: "",
    recaptchaVerified: false,
    errorText: "",
    messageSending: false,
    messageSent: false
  };

  setFormError(jsonErrors, otherError) {
    if (jsonErrors) {
      for (let err of jsonErrors) {
        if (err.param === "email") {
          this.setState({ errorText: "Invalid email address" });
        } else {
          this.setState({ errorText: `"${err.param}": ${err.msg}` });
        }
      }
    } else {
      this.setState({ errorText: otherError });
    }
  }

  handleRecaptchaVerify = token => {
    call_houseshakers_api("POST", "verify", { token: token })
      .then(() => {
        this.setState({ recaptchaVerified: true });
      })
      .catch(() => {
        this.setState({
          recaptchaVerified: false,
          errorText: "Not verified"
        });
      });
  };

  handleFieldChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ messageSending: true, errorText: "" });

    call_houseshakers_api("POST", "contact", this.state)
      .finally(() => {
        this.setState({ messageSending: false });
      })
      .then(() => {
        this.setState({ messageSent: true });
      })
      .catch(e => {
        this.setFormError(
          e.json ? e.json.errors : null,
          `Communication error: ${e.message}`
        );
      });
  };

  render() {
    if (this.state.messageSent) {
      return (
        <div className="text-left mt-4">
          <h3 className="text-success">Your message has been sent.</h3>
          <div>Please expect a response within 1-2 days. Thank you!</div>
        </div>
      );
    }

    let venueSectionClass = this.state.reason === "booking" ? "" : "d-none";
    let sendButtonHtml = "Send";

    if (this.state.messageSending)
      sendButtonHtml = (
        <div className="spinner-border" role="status">
          <span className="sr-only">Sending...</span>
        </div>
      );

    return (
      <form className="contact_form text-center" onSubmit={this.handleSubmit}>
        <div className="fix-width-ie d-inline-block text-left">
          <div className="row align-items-end">
            <div className="col-auto">
              <ContactFormField
                value={this.state.reason}
                onChange={this.handleFieldChange}
                type="radio"
                name="reason"
                label="Reason for Contacting:"
                options={[
                  { label: "General comments/questions", value: "general" },
                  { label: "Request booking", value: "booking" }
                ]}
                required={true}
              />
              <ContactFormField
                value={this.state.method}
                onChange={this.handleFieldChange}
                type="radio"
                name="method"
                id="cf_method"
                label="Preferred Response Method:"
                options={[
                  { label: "Email", value: "email" },
                  { label: "Phone", value: "phone" }
                ]}
                required={true}
              />
              <ContactFormField
                value={this.state.submitterName}
                onChange={this.handleFieldChange}
                name="submitterName"
                id="cf_submitterName"
                label="Your Name:"
                required={true}
              />
              <ContactFormField
                value={this.state.email}
                onChange={this.handleFieldChange}
                type="email"
                name="email"
                id="cf_email"
                label="Email:"
                required={this.state.method === "email"}
              />
              <ContactFormField
                value={this.state.phone}
                onChange={this.handleFieldChange}
                type="tel"
                name="phone"
                id="cf_phone"
                label="Phone:"
                required={this.state.method === "phone"}
              />
            </div>
            <div className={"cf_right_col col " + venueSectionClass}>
              <ContactFormField
                value={this.state.contactName}
                onChange={this.handleFieldChange}
                name="contactName"
                id="cf_contactName"
                label="Contact Name:"
                subtext="(If you're not the contact)"
                required={false}
              />
              <ContactFormField
                value={this.state.veune}
                onChange={this.handleFieldChange}
                name="venue"
                id="cf_venue"
                label="Venue:"
                required={this.state.reason === "booking"}
              />
              <ContactFormField
                value={this.state.address}
                onChange={this.handleFieldChange}
                name="address"
                id="cf_address"
                label="Address:"
                required={this.state.reason === "booking"}
              />
              <ContactFormField
                value={this.state.date}
                onChange={this.handleFieldChange}
                type="date"
                name="date"
                id="cf_date"
                label="Date:"
              />
              <ContactFormField
                value={this.state.time}
                onChange={this.handleFieldChange}
                type="time"
                name="time"
                id="cf_time"
                label="Time:"
              />
            </div>
          </div>
          <div className="row cf_comments_container">
            <div className="col-12">
              <ContactFormField
                value={this.state.comments}
                onChange={this.handleFieldChange}
                label="Additional Comments:"
                name="comments"
                id="cf_comments"
                type="textarea"
              />
            </div>
          </div>
        </div>
        <div className="text-center text-danger h5 font-weight-bold mt-4">
          {this.state.errorText}
        </div>
        <div className="text-center mt-4">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              !this.state.recaptchaVerified || this.state.messageSending
            }
          >
            {sendButtonHtml}
          </button>
        </div>
        <div className="text-center mt-1">
          <ReCaptchaControl onVerify={this.handleRecaptchaVerify} />
        </div>
      </form>
    );
  }
}

export default ContactForm;
